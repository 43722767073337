import React from 'react'

import Layout from '../Layout'
import SEO from '../SEO'

const Legal = () => (
  <Layout withPadding={false} restricted={false}>
    <SEO title="Politique de vente" />
    Politique de vente
  </Layout>
)

export default Legal
